
#map {
    width: 100%;
    height: 100vh;
}

.map-container {
  height: 100vh;
}

.marker {
  padding: 0.5rem;
  background: black;
  transition: all 0.3s ease-out;
  position: relative;
  width: 100px;
  border-radius: 5px;
}

.vizMarker {
  background-size: cover;
  width: 50px;
  height: 50px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.vizMarkerText {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
}

.map-pin-container {
  text-align: center;
}

.map-pin {
  width: 50px;
  height: 50px;
  filter: invert(49%) sepia(36%) saturate(4633%) hue-rotate(163deg) brightness(96%) contrast(101%);
}

.map-pin-title {
  background: #00a4d6;
  color: white;
  font-weight: bold;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.map-pin-info {
  background: #d4d6d6;
  padding-left: 4px;
  padding-right: 4px;
}

.gm-svpc > div { 	height: 100%; 	width: 100%; }
.gm-style-moc {
  height: unset !important;
}

.mapboxgl-popup-content {
  background-color: #a5a5a6 !important;
  border-color: #1f261f !important;
  max-width: 320px !important;
  /* box-shadow: 3px 3px 2px #5a595c !important; */
}

.link { 
  color: #4287f5; 
  text-decoration: none; 
} 

.hideme {
  display: none;
}

.link_darkmode { 
  color: #ffffff; 
  text-decoration: none; 
} 

.link_lightmode { 
  color: #000000; 
  text-decoration: none; 
} 

.link_darkmode_under { 
  color: #ffffff; 
  text-decoration: underline; 
} 

.link_lightmode_under { 
  color: #000000; 
  text-decoration: underline; 
} 

.iframe-max { flex-grow: 1; border: none; margin: 0; padding: 0; }